<div class="fullHeight" [ngClass]="{'loader-active': isApiLoading, 'extraPaddingContent': extraPaddingContent}">
<div *ngIf="isApiLoading" class="loader-container">
  <mat-spinner></mat-spinner>
</div>

<mat-drawer-container autosize="" *ngIf="!isApiLoading">
  <mat-drawer #drawer opened="false" mode="push" position="end">
    <mat-nav-list>
      <section class="wrapSlideMenuHeader">
        <p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.JumpToSection" | translate}}</p>
        <a id="dnload-wkst" class="makeCertainSize" mat-raised-button href="{{'SharedItems.WorkSheetUrl' | translate}}" target="_blank" (click)="downloadGA4Push()">
          {{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.ViewWorksheet" | translate}}
        </a>
      </section>

      <section class="wrapSlideMenuBody">
        <div *ngFor="let part of sideNavParts; let partIndex = index" class="wrapPartSections">
          <!-- Part Title and Progress -->
          <div class="headerDivInSlideMenuBody">
            <h4><b>{{'SharedItems.Part' | translate}} {{ part.partNumber }}{{'SharedItems.SemiColomn' | translate}} {{ part.partName }}</b></h4>
            <p>{{ getCompletedSectionsCount(partIndex) }} {{'SharedItems.of' | translate}} {{ getTotalSectionsCount(partIndex) }} {{'SharedItems.Completed' | translate}}</p>
          </div>
      
          <!-- Navigation Section Dots and Links -->
          <section class="mainNavSectionInSlideMenuBody">
            <div class="wrapDotsAndWords">
              <!-- Navigation Dots -->
              <div class="navigationDotsInSlideMenu">
                
                <ng-container *ngFor="let section of (sideNavSections[partIndex] || []); let i = index; let last = last">
                  <a class="dots" [ngClass]="{ 'outerBorder': dynamicCurrentSectionIndexForSideNav[partIndex] === i }">
                    <div
                      class="mini-dot"
                      *ngIf="completedSectionsPerPart[partIndex]"
                      [ngClass]="{
                        'activeDot': completedSectionsPerPart[partIndex][i],
                        'currentDot': dynamicCurrentSectionIndex === i
                      }"
                    >
                      <svg id="icon_-_checkmark_small" xmlns="http://www.w3.org/2000/svg" width="14.142" height="10.607" viewBox="0 0 14.142 10.607">
                        <rect id="Rectangle_10" width="2" height="13" rx="1" transform="translate(12.728) rotate(45)" fill="#fff" />
                        <rect id="Rectangle_11" width="2" height="7" rx="1" transform="translate(0 5.657) rotate(-45)" fill="#fff" />
                      </svg>
                    </div>
                  </a>
                  <span *ngIf="!last"></span>
                </ng-container>
              </div>
      
              <!-- Navigation Text Links -->
              <div class="textCloseToDotsInSideMenu">
                <ng-container *ngFor="let section of (sideNavSections[partIndex] || []); let i = index">
                  <a (click)="closeSideMenu(section.id)">
                    <span
                      class="wrapLinkInSideMenu"
                      (click)="scrollToSection(section.id)"
                      [routerLink]="['/onboarding/part', partIndex + 1]"
                      [fragment]="section.id"
                    >
                      <p>{{ section.sectionName }}</p>
                      <span class="arrowheadInSideMenu">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.556" height="15.556" viewBox="0 0 15.556 15.556">
                          <g id="icon_-_arrow_medium" transform="translate(7.778 15.556) rotate(-135)">
                            <rect id="Rectangle_10" width="2" height="11" rx="1" fill="#6e6e6e" />
                            <rect id="Rectangle_11" width="2" height="11" rx="1" transform="translate(0 11) rotate(-90)" fill="#6e6e6e" />
                          </g>
                        </svg>
                      </span>
                    </span>
                  </a>
                </ng-container>
              </div>
            </div>
          </section>
        </div>
      </section>      

      <!-- Close Side Menu Button -->
      <button class="closeButtonInSideMenu" mat-raised-button (click)="closeSideMenu()">
        <div class="wrapTextAndSVGInSideMenu">
          <p>{{"DetailedSectionOne.AnimatedMapFooter.SideMenuSection.CloseMenu" | translate}}</p>
          <svg id="icon_-_close_medium" xmlns="http://www.w3.org/2000/svg" width="15.557" height="15.556" viewBox="0 0 15.557 15.556">
            <rect id="Rectangle_10" width="2" height="20" rx="1" transform="translate(0 1.414) rotate(-45)" fill="#066fef"/>
            <rect id="Rectangle_11" width="2" height="20" rx="1" transform="translate(1.414 15.556) rotate(-135)" fill="#066fef"/>
          </svg> 
        </div>
      </button>
    </mat-nav-list>
  </mat-drawer>

  <!-- Drawer Content -->
  <mat-drawer-content class="customColorForDrawer" [ngClass]="{'extraPaddingContent': extraPaddingContent}">
    <div class="outerComponentColor customScrollInAnimatedContent">
      <div class="wrapMapComponent">
        
        <nav class="navigationForOnboardingComponent navigationWithPositionAbsolute">
          <div>
            <a [routerLink]="'/'">{{'SharedItems.Home' | translate}}</a>
            <span> > </span>
            <span>{{ sideNavParts[currentPartIndex]?.breadCrumb || 'About FGE' }}</span>
          </div>
          <div>
            <h1>{{ sideNavParts[currentPartIndex]?.partName || 'Part Name' }}</h1>
          </div>
        </nav>
        
        <!-- Part Content -->
        <div class="sectionBeforeAnimatedContent" *ngIf="sideNavParts[currentPartIndex]?.textAfterPartName">
          <div class="customSingleParagraphs">
            <p [innerHTML]="sideNavParts[currentPartIndex]?.textAfterPartName || ''"></p>
          </div>
        </div>

        <!-- Sections Content -->
        <div class="outerComponentColor customScrollInAnimatedContent" [ngClass]="getMarketClass(sectionsFromApi[0]?.market)">
          <div class="wrapMapComponent">
            <!-- Display sections -->
            

            <div *ngIf="sectionsFromApi.length > 0; else noData" class="cmsContainer">
              <article *ngFor="let section of sectionsFromApi; let i = index" class="content" id="{{ section.id }}">
                <div class="wrapAnimatedContent">
                  <!-- Section Text -->
                  <div class="leftText rellax" [attr.data-rellax-speed]="section.rellaxSpeed">
                    <p class="sectionNumberHeader">{{'SharedItems.Section' | translate}} {{ calculateSectionNumber(i) }}</p>
                    <h3 class="sectionNameHeader">{{ section.sectionName }}</h3>
                    <p class="sectionNameParagraph">{{ section.sectionDescription }}</p>
                    <a class="sectionLink" mat-raised-button [routerLink]="['/onboarding/part', currentPartIndex + 1, section.id]" (click)="amplitudeViewSectionPush(section.sectionName)">
                      {{'SharedItems.VeiwSectionButton' | translate}} 
                    </a>
                  </div>
            
                  <!-- Section Images -->
                  <div class="animatedImagesHolder">
                    <ng-container *ngFor="let image of section.images">
                      <img
                        [src]="image.src"
                        [attr.alt]="image.alt"
                        [attr.data-aos]="image.animation"
                        [attr.data-aos-delay]="image.delay"
                        [attr.data-aos-duration]="image.duration"
                        class="customImageInTheMainFlow"
                      />
                    </ng-container>
                  </div>
                </div>
              </article>
            </div>
            <ng-template #noData>
              <p>No content available.</p>
            </ng-template>            
            
          </div>
        </div>
        

        <!-- Next Part Button -->
        <div class="explore-btm-btn" *ngIf="showNextPartButton()">
          <a class="sectionLink shortenButton" mat-raised-button (click)="navigateToNextPart()">
            {{"DetailedSectionOne.AnimatedMapFooter.GoToNextPartButton" | translate}}
          </a>
        </div>
      </div>

      <!-- Footer -->
      <footer class="footerForMapComponent">
        <div class="wrapfootercomponents">
          <section>
            <p class="flexedP_inFooter {{'DetailedSectionOne.AnimatedMapFooter.ShorterLength' | translate}}">
              <span class="blueBoldText">
                {{'SharedItems.Part' | translate}} {{ sideNavParts[currentPartIndex]?.partNumber }}{{'SharedItems.SemiColomn' | translate}}
              </span>
              <span class="solveSpacing">
                {{ sideNavParts[currentPartIndex]?.partName }}
              </span>
              <br class="showOnMobileOnly" /> <span class="dontShowOnMobile">|</span> 
              <span class="lightBlueText longWordsShallNotPass">
                {{ currentSectionName }}
              </span>
            </p>
          </section>

          <div class="containerInMap">
            <ng-container *ngIf="sideNavSections && sideNavSections[currentPartIndex]?.length">
              <ng-container *ngFor="let section of sideNavSections[currentPartIndex]; let i = index; let last = last">
              <a class="dots"
                (click)="scrollToSection(section.id)"
                [routerLink]="['/onboarding/part', currentPartIndex + 1]" 
                [fragment]="section.id"
                [ngClass]="{ 'outerBorder': dynamicCurrentSectionIndex === i }">
                <div
                class="mini-dot"
                [ngClass]="{'activeDot': completedSectionsPerPart[currentPartIndex][i]}"
                ></div>
              </a>
              <span *ngIf="!last"></span>
              </ng-container>
            </ng-container>
          </div>
          
          
          <section>
            <span class="viewSections">{{"DetailedSectionOne.AnimatedMapFooter.RightSection" | translate}}</span>
            <button (click)="toggleMenu(); hideConfirmation(); closeToastOnClick();" mat-icon-button [class.active]="isActiveButton" aria-label="Example icon-button with menu icon">
              <svg _ngcontent-ng-c875265465="" xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16"><g _ngcontent-ng-c875265465="" id="icon_-_hamburger_menu_-_default" data-name="icon - hamburger menu - default" transform="translate(6)"><rect _ngcontent-ng-c875265465="" id="Rectangle_11" data-name="Rectangle 11" width="2" height="18" rx="1" transform="translate(0 2) rotate(-90)" fill="#066FEF"></rect><rect _ngcontent-ng-c875265465="" id="Rectangle_169" data-name="Rectangle 169" width="2" height="18" rx="1" transform="translate(0 9) rotate(-90)" fill="#066FEF"></rect><rect _ngcontent-ng-c875265465="" id="Rectangle_170" data-name="Rectangle 170" width="2" height="18" rx="1" transform="translate(0 16) rotate(-90)" fill="#066FEF"></rect></g><g _ngcontent-ng-c875265465="" id="icon_-_hamburger_menu_-_default-2" data-name="icon - hamburger menu - default"><rect _ngcontent-ng-c875265465="" id="Rectangle_11-2" data-name="Rectangle 11" width="2" height="3" rx="1" transform="translate(0 2) rotate(-90)" fill="#0f0f0f"></rect><rect _ngcontent-ng-c875265465="" id="Rectangle_169-2" data-name="Rectangle 169" width="2" height="3" rx="1" transform="translate(0 9) rotate(-90)" fill="#0f0f0f"></rect><rect _ngcontent-ng-c875265465="" id="Rectangle_170-2" data-name="Rectangle 170" width="2" height="3" rx="1" transform="translate(0 16) rotate(-90)" fill="#0f0f0f"></rect></g></svg>
            </button>
          </section>
        </div>
      </footer>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
