<div class="fullHeight" [ngClass]="{'extraPaddingContent': extraPaddingContent}">

<div class="contentWrapper pb-30">
  <div class="loaderHolder" *ngIf="apiStatus === 'pending'">
    <mat-spinner></mat-spinner>
  </div>
  <div class="breadcrumbs"><a routerLink='/'>{{'SearchPage.Home' | translate}}</a> > <a routerLink='/support-guide'>{{'SearchPage.SupportGuide' | translate}}</a> > {{'SearchPage.Search' | translate}}</div>
  <div class="page-header inline-element mt-20 align-bottom">{{'SearchPage.Search' | translate}}</div>
  <div class="custom-tooltip">
    <img class="tooltip_image" (click)="showTooltip(searchTooltip)" src="../../../assets/logos/tool_tip.svg" alt="Tooltip Image"> 
     <div class="tooltip-content" id="searchTooltip" #searchTooltip>
      <img src="../../../assets/triangle.png" alt="" class="tooltip-arrow">
        <p>{{'SearchPage.SearchTooltipText' | translate}}</p>
        <button mat-button  class="main-button got-it-btn" (click)="hideTooltip(searchTooltip)">{{'SearchPage.GotIt' | translate}}</button>
     </div>
  </div>
  | &nbsp;&nbsp;<img class="vertical-middle" src="../../../assets/arrow-left-light.svg" alt=""><a routerLink="/support-guide" class="inline-element"><span class="niceLineFromSomething colorItLikeLink colorItLikeDarkLink">{{'SearchPage.BackToCategories' | translate}}</span></a>
  <div class="mt-30 sg-wrapper flex-layout">
      <div class="search-left-column">            
          <mat-form-field class="sg-search">
              <span matPrefix> </span>
              <input type="text" id="mainSearch" [(ngModel)]="searchTerm" matInput placeholder="{{'SearchPage.SearchPlaceholder' | translate}}" name= "search" #searchInputMain (keyup.enter)="searchItem(searchInputMain.value)" [value] = "searchTerm">
              <button class="search-button" matSuffix mat-button (click)="searchItem(searchInputMain.value)" [disabled]="!searchTerm" aria-label="search"><mat-icon >search</mat-icon></button>
          </mat-form-field>         
      </div>
      <div class="search-right-column">
        <div id="content-type-click" class="sg-top-selector-row">
          <div class="sg-selector active" id="content-type-all" tabindex="0" (click)="changeItem($event, null)">{{'SearchPage.All' | translate}}</div>
          <div class="sg-selector" [id]="item.id"  *ngFor="let item of resContentTypesForHTML" tabindex="0" (click)="changeItem($event, item.id)" [innerHTML]="item?.name"></div>
        </div>        
      </div>
      <div class="search-parameter">
        <mat-form-field class="sort-select">
          <mat-label>{{'SearchPage.Sort' | translate}}</mat-label>          
            <mat-select [(value)]="selected">
            <mat-option value="Most Relevant" (click)="changeSearchParameters('MostRelevant')">{{'SearchPage.MostRelevant' | translate}}</mat-option>
            <mat-option value="Newest" (click)="changeSearchParameters('Newest')">{{'SearchPage.Newest' | translate}}</mat-option>
            <mat-option value="Most Popular" (click)="changeSearchParameters('MostPopular')">{{'SearchPage.MostPopular' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>       
  </div>
  <div class="itemsAmount">
    <b *ngIf="totalItems == 0">{{totalItems}} {{'SearchPage.ResultsFound0' | translate}}</b>
    <b *ngIf="totalItems > 1">{{totalItems}} {{'SearchPage.ResultsFound' | translate}}</b>
    <b *ngIf="totalItems == 1 ">{{totalItems}} {{'SearchPage.ResultFound' | translate}}</b> 
    <span *ngIf="searchInputMain.value.length > 0"><b>{{'SearchPage.QuotationMarkBefore' | translate}}{{searchWordForPaginator}}{{'SearchPage.QuotationMarkAfter' | translate}}</b></span>
  </div>
  
  <div class="categories-container">
    <div class="categories-block">
      <div class="category-row">
        <mat-card class="category-card search-card" *ngFor="let card of searchCards">
          <a mat-raised-button class="linkInTheCategoryCard" [routerLink]="'/support-guide/details/' + card.resourceId" (click)="resourceSearchGA4Push(card.category, card.title, card.contentType, 'Search Page'); ampliResourceSearchPush(card.category, card.title, card.contentType)">
            <div class="wrapIcon">
              <img [src]="card.categoryThumbnailUrl" alt="Category Image">
            </div>
            <div class="wrapContent">
              <mat-card-header>
                <mat-card-title-group>
                  <mat-card-title [innerHTML]="highlightMatches(card.title, searchTerm)"></mat-card-title>
                </mat-card-title-group>
              </mat-card-header>
              <mat-card-content [innerHTML]="highlightMatches(card.description, searchTerm)"></mat-card-content>
            </div>
            <div class="wrapDataUnderHeaderInDetailedSection">
              <p>{{ card.category }}</p>
              <p>·</p>
              <p>{{ card.contentType }}</p>
            </div>
          </a>
          <div class="ifBookmarked">
            <img (click)="onBookmarkIconClick({ resourceId: card.resourceId, isFavorite: card.isFavorite }, card.title)" src="../../../assets/logos/bookmarked-true.svg" alt="Bookmark Image" *ngIf="card.isFavorite">
            <img (click)="onBookmarkIconClick({ resourceId: card.resourceId, isFavorite: card.isFavorite }, card.title)" src="../../../assets/logos/bookmark_item.svg" alt="Bookmark Image" *ngIf="!card.isFavorite">
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="custom-paginator" *ngIf="pagesList.length > 0">
    {{'SupportGuide.Page' | translate}}&nbsp;&nbsp;&nbsp;
    <div id="arrowPrev" class="prev-page hidden" *ngIf="pagesList.length > 1">
        <img src="../../../assets/arrow-left.png" alt="arrow-left" tabindex="0" (click)="changePage(prevPage, true)">
    </div>
    <div id="page{{page}}" aria-label="Page" class="custom-page" [ngClass]="{ 'active': page === activePage }" *ngFor="let page of pagesList" tabindex="0" (click)="changePage(page, null)">{{page}}</div>
    <div id="arrowNext" class="next-page" *ngIf="pagesList.length > 1">
        <img src="../../../assets/arrow-right.png" alt="arrow-right" tabindex="0" (click)="changePage(nextPage, false)">
    </div>
  </div>
  <div *ngIf="pagesList.length == 0 && apiStatus !== 'pending'">    
    <div class="no-results-text mb-10">{{'SearchPage.NoResults' | translate}}</div>
    <div class="no-results-text mb-10">
      <ul>
        <li>{{'SearchPage.TryAdjust' | translate}}</li>
        <li>{{'SearchPage.TryRefining' | translate}}</li>
      </ul>
    </div>
  </div>
</div>

</div>