import { Injectable } from '@angular/core';
import { ProfileService } from '../APIs/profile.service';
import { init, logEvent, setUserId, identify, Identify } from '@amplitude/analytics-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AmpliAnalyticsService {
  userId: any;
  market: any;
  userType: any;
  country: any;
  storeId: any;
  dealerName: any;

  constructor(private profileService: ProfileService) {
    init(environment.ampli_key);
  }

  ampliCategoryChangePush(category_name: string) {
    logEvent('category_change', { category_name });
  }

  ampliCategorySelectionPush(category_name: string) {
    logEvent('category_selection', { category_name });
  }

  ampliLoginPush() {
    this.profileService.getProfile().subscribe((data: any) => {
      this.userId = data.userId;
      this.market = data.marketCode;
      this.userType = data.userType;
      this.country = data.countryName;
      this.storeId = data.storeId;
      this.dealerName = data.dealerName;

      sessionStorage.setItem('userId', this.userId);
      sessionStorage.setItem('marketCode', this.market);
      sessionStorage.setItem('userType', this.userType);
      sessionStorage.setItem('countryName', this.country);
      sessionStorage.setItem('storeId', this.storeId);
      sessionStorage.setItem('dealerName', this.dealerName);

      setUserId(this.userId);

      const ampliUserProperties = new Identify()
        .set('market', this.market)
        .set('user_type', this.userType)
        .set('country', this.country)
        .set('store_id', this.storeId)
        .set('dealer_name', this.dealerName);

      identify(ampliUserProperties);

      logEvent('login', {
        user_id: this.userId,
        market: this.market,
        user_type: this.userType,
        country: this.country,
        store_id: this.storeId,
        dealer_name: this.dealerName,
      });
    });
  }

  ampliRefineResourceTypePush(resource_type: string) {
    logEvent('refine_resource_type', { resource_type });
  }

  ampliResourceEngagementBookmarkPush(resource_name: string) {
    logEvent('resource_engagement_bookmark', { resource_name });
  }

  ampliDownloadResourcePush(resource_name: string) {
    logEvent('resource_engagement_download', { resource_name });
  }

  ampliResourceEngagementPreviewPush(resource_name: string) {
    logEvent('resource_engagement_preview', { resource_name });
  }

  ampliResourceEngagementRatePush(resource_name: string, rating: number) {
    logEvent('resource_engagement_rate', { resource_name, rating });
  }

  ampliResourceEngagementSharePush(resource_name: string) {
    logEvent('resource_engagement_share', { resource_name });
  }

  ampliResourceSelectedBookmarkPush(category_name: string, resource_name: string, resource_type: string) {
    logEvent('resource_selected_bookmark', { category_name, resource_name, resource_type });
  }

  ampliResourceSelectedCategoryPush(category_name: string, resource_name: string, resource_type: string) {
    logEvent('resource_selected_category', { category_name, resource_name, resource_type });
  }

  ampliResourceSelectedResourcePush(category_name: string, resource_name: string, resource_type: string) {
    logEvent('resource_selected_resource', { category_name, resource_name, resource_type });
  }

  ampliResourceSelectedSearchPush(category_name: string, resource_name: string, resource_type: string, search_query: string, search_result_total: number) {
    logEvent('resource_selected_search', { category_name, resource_name, resource_type, search_query, search_result_total });
  }

  ampliCompleteSectionPush(section_name: string) {
    logEvent('section_complete', { section_name });
  }

  ampliSectionModalOpenPush(section_name: string) {
    logEvent('section_modal_open', { section_name});
  }

  ampliSiteSearchResultPush(search_query: string, search_result_total: number) {
    logEvent('site_search_results', { search_query, search_result_total });
  }

  ampliViewSectionPush(section_name: string) {
    logEvent('view_section', { section_name });
  }
}
