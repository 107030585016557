import { Component, OnInit, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { SearchService } from '../APIs/search.service'; 
import { CardService } from '../APIs/card.service'; 
import { SharedService } from '../shared.service';
import { HelperService } from '../services/helper.service';
import { ProfileService } from '../APIs/profile.service';
import { HttpResponse } from '@angular/common/http';
import { DialogContentMainDialog, OnboardingModalComponent } from '../onboarding-modal/onboarding-modal.component';
import { RouteService } from '../services/route.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../APIs/allLanguages.service';
import { ToastService } from '../../app/shared.service';
import { Subscription } from 'rxjs';
import { CMSHomePageService } from '../APIs/cms_content/homePage.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AmpliAnalyticsService } from '../tag-manager/amplitude';

@Component({
  selector: 'app-main-flow-components',
  templateUrl: './main-flow-components.component.html',
  styleUrls: ['./main-flow-components.component.scss'],
})

export class MainFlowComponentsComponent implements OnInit, AfterViewInit, OnDestroy {
  APICards: any[] = [];    
  initialCards: any[] = []; 
  searchTerm: string = '';
  searchWord: string = '';
  totalCategories: number = 0;  
  categoryIdClicked: any;
  showCount:number = 8;
  showStep:number = 8;
  apiStatus: string = 'pending';
  isStyleLoading: boolean = true;
  languages: any[] = [];
  marketLanguages: any[] = [];
  siteLang = "";
  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;
  aboutFGELabel: string = '';
  aboutFGETooltipText: string = '';
  aboutFGETooltipButtonText: string = '';
  aboutFGEText: SafeHtml = '';
  exploreFGEButton: string = '';
  viewWorksheetLabel: string = '';
  viewWorksheetLinkPDF: string = '';
  aboutFGEImage: string = '';
  aboutFGEImageAlt: string = '';
  fGESupportGuideLabel: string = '';
  aboutTheSupportGuideLabel: string = '';
  viewAllCategoriesLabel: string = '';
  searchDefaultText: string = '';
  idsData: any;
  contentId: string | null = null;

  constructor(private cardService: CardService, private routeService: RouteService, private searchService: SearchService,
    private dataService: SharedService, private helperService: HelperService,
    private profileService: ProfileService, private dialogContentMainDialog: DialogContentMainDialog,
    private onboardingModalComponent: OnboardingModalComponent, private tagService: GA4Service, private translateService: TranslateService, 
    private languageService: LanguageService, private toastService: ToastService,
    private ampliAnalyticsService: AmpliAnalyticsService,
    private cmsService: CMSHomePageService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef
) {} 

  ngOnInit(): void {
    const showToastStatus = sessionStorage.getItem("showToastClosed");
    
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);

    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });

    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.focus();
    }
    
    this.languageService.getLanguages().subscribe((data: any) => {
      this.languages = data.languages;
      this.languages.forEach( (item, index) => {
        if(item.market.toLowerCase() === localStorage.getItem("market")?.toLowerCase()) {
         this.marketLanguages.push(this.languages[index]);                      
        } 
        
        this.siteLang = localStorage.getItem('siteLang') || this.marketLanguages[0]?.code;
        let defaultLanguageCode = this.marketLanguages[0]?.code; 
        if (!localStorage.getItem('siteLang')) {
          localStorage.setItem('siteLang', defaultLanguageCode);
        } 
      });
      this.fetchContent("home_page");
    });
    const isFirstLoad = this.helperService.isFirstLoad();    

    if (isFirstLoad) {
      this.profileService.createOrUpdate().subscribe(
        (response: HttpResponse<any>) => {
          if (response && response.status === 200) {
            this.getUserAccount(response.status);
            this.loadCategories();
            this.fetchContent("home_page");
            this.tagService.loginPush();
            this.ampliAnalyticsService.ampliLoginPush();
            setTimeout(()=>{
              window.location.reload()
            }, 500)
          } else if (response && response.status === 201) {
            this.getUserAccount(response.status);
            this.loadCategories();
            this.fetchContent("home_page");
            this.tagService.loginPush();
            this.ampliAnalyticsService.ampliLoginPush();
          } else if (response && response.status === 202) {
            this.getUserAccount202(response.status);
            this.loadCategories();
            this.fetchContent("home_page");
          } else {
            this.routeService.serverError();
            setTimeout(()=>{
              window.location.reload()
            }, 500)
          }
        }
      );
    }
    else {
      this.tagService.refreshPush();
      this.loadCategories();
      this.fetchContent("home_page");
      this.ampliAnalyticsService.ampliLoginPush();
    }

    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    const linkElement = document.querySelector('link[href*="styles.css"]') as HTMLLinkElement | null;
  
    if (linkElement) {
      linkElement.onload = () => {
        this.isStyleLoading = false;
      };
    }

    this.fetchContent("home_page");
    this.isStyleLoading = false;
    this.cdr.detectChanges();
  }

  fetchContent(contentType: string): void {
    this.cmsService.getContentData().subscribe({
      next: (content) => {
        if (content) {
          this.mapDataToMainFlowComponent(content);
        }
      },
    });
  }

  mapDataToMainFlowComponent(content: any): void {
    if (!content) {
      return;
    }
  
    // Use properties directly if items are not available
    const properties = content.properties || content.items?.[0]?.properties;
    if (!properties) {
      return;
    }
  
    this.aboutFGELabel = properties.aboutFGELabel || '';
    this.aboutFGETooltipText = this.extractHtml(properties.aboutFGETooltipText || '');
    this.aboutFGETooltipButtonText = properties.aboutFGETooltipButtonText || '';
    this.aboutFGEText = this.sanitizer.bypassSecurityTrustHtml(properties.aboutFGEText || '');
    this.exploreFGEButton = properties.exploreFGEButton || '';
    this.viewWorksheetLabel = properties.viewWorksheetLabel || '';
    this.viewWorksheetLinkPDF = properties.viewWorksheetLinkPDF || '';
    this.aboutFGEImage = this.extractImageSrc(properties.aboutFGEImage || '');
    this.aboutFGEImageAlt = properties.aboutFGELabel || ''; // Use a default alt
    this.fGESupportGuideLabel = properties.fGESupportGuideLabel || '';
    this.aboutTheSupportGuideLabel = properties.aboutTheSupportGuideLabel || '';
    this.viewAllCategoriesLabel = properties.viewAllCategoriesLabel || '';
    this.searchDefaultText = properties.searchDefaultText || '';
  }
  
  
  extractHtml(html: string): string {
    if (!html) return ''; // Handle undefined or null
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(html, 'text/html');
    return parsedDoc.body.textContent || ''; // Get plain text from HTML
  }
  
  extractImageSrc(html: string): string {
    if (!html) return ''; // Handle undefined or null
    const parser = new DOMParser();
    const parsedDoc = parser.parseFromString(html, 'text/html');
    const img = parsedDoc.querySelector('img');
    return img?.getAttribute('src') || '';
  }

  private loadCategories() {
    this.cardService.getCards().subscribe((data) => {
      const typedData = data as { items: any[] };
      this.initialCards = typedData.items;      
      this.totalCategories = data.total;      
      if (this.totalCategories <= 12){
          this.cardService.getCardsWithParam(this.totalCategories).subscribe((data) => {          
          const typedData = data as { items: any[] };          
          this.initialCards = typedData.items;
          this.showCount = this.totalCategories;
          this.sortAPICardsBySequence();                 
        });
      }
      sessionStorage.setItem("CategoriesNumber", data.total);
      this.sortAPICardsBySequence();
      this.apiStatus = 'success';      

    });
  }

  sortAPICardsBySequence() {   
    this.APICards = [...this.initialCards].sort((a, b) => a.sequence - b.sequence);
  }  

  searchItem(searchWord: string) {   
    this.dataService.changeSearchData(searchWord);
    sessionStorage.setItem("SearchWord", searchWord);        
  }

  onSearchClick(searchTerm: string) {
    if (searchTerm.trim() === '') {
      this.sortAPICardsBySequence();
    } else {
      this.APICards = this.filterAndHighlightCards(searchTerm);
    }
  }

  filterAndHighlightCards(searchTerm: string): any[] {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    const filteredCards = this.initialCards.filter((card) => {
      return (
        card.categoryTitle.toLowerCase().includes(lowerCaseSearchTerm) ||
        (card.categoryDescription && card.categoryDescription.toLowerCase().includes(lowerCaseSearchTerm))
      );
    }); 

    return filteredCards.map((card) => {
      const cardCopy = { ...card };
      cardCopy.categoryTitle = this.highlightMatches(card.categoryTitle, lowerCaseSearchTerm);
      if (card.categoryDescription) {
        cardCopy.categoryDescription = this.highlightMatches(card.categoryDescription, lowerCaseSearchTerm);
      }
      return cardCopy;
    });
  } 

  highlightMatches(text: string, searchTerm: string): string {
    if (!searchTerm) {
      return text;
    }
    const regex = new RegExp(searchTerm, 'gi');
    return text.replace(regex, (match) => `<span class="highlighted">${match}</span>`);
  }

  saveId(id: any, title: any){
    sessionStorage.setItem("Id", id);
    this.categoryIdClicked = id;   
    this.dataService.trackCatId(this.categoryIdClicked);
    this.tagService.homeCategoryClickPush(title);
    this.ampliAnalyticsService.ampliCategorySelectionPush(title);
  }  

  showMoreCategories(){
    this.showCount = this.showCount + this.showStep;    
    this.cardService.getCardsWithParam(this.totalCategories).subscribe((data) => {
      const typedData = data as { items: any[] };
      this.initialCards = typedData.items;      
      this.totalCategories = data.total;
      this.sortAPICardsBySequence();
    });    
  }

  showTooltip(id:any){
    document.getElementById(id.id)?.setAttribute("style", "display: block;");;
  }

  hideTooltip(id:any){
    document.getElementById(id.id)?.setAttribute("style", "display: none;");;
  }
  downloadGA4Push(){
    this.tagService.downloadPush();
  }

  getUserAccount(status: any) {
    this.profileService.getProfile().subscribe((data: any) =>{
      const userLang = data.language;
      if (status == "201"){
        if (this.marketLanguages.length > 1) {
          this.onboardingModalComponent.openWelcomeDialog();
        } else {
          this.dialogContentMainDialog.openMainModal();
        }
      }
      if (!localStorage.getItem('siteLang') || localStorage.getItem('siteLang') == "undefined"){
        localStorage.setItem('siteLang', userLang);
      }
      this.translateService.use(userLang);
    });
  }

  getUserAccount202(status: any) {
    this.profileService.getProfile().subscribe((data: any) =>{
      this.languageService.getLanguages().subscribe((data: any) => {
        this.languages = data.languages;
        localStorage.setItem('siteLang', this.marketLanguages[0].code);          
        this.translateService.use(this.marketLanguages[0].code);       
        if (this.marketLanguages.length > 1){
          this.onboardingModalComponent.openWelcomeDialog();
        } else {
          this.languageService.updateUserLanguage(this.marketLanguages[0].code).subscribe((response) => {
            this.cardService.getCards().subscribe((data) => {
              const typedData = data as { items: any[] };
              this.initialCards = typedData.items;      
              this.totalCategories = data.total;
              sessionStorage.setItem("CategoriesNumber", data.total);
              this.apiStatus = 'success';
            });
          });
          this.translateService.use(this.marketLanguages[0].code);
          localStorage.setItem('siteLang', this.marketLanguages[0].code);
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }        
      });
   
    });    
  }

  ngOnDestroy() {
    this.showToastSubscription?.unsubscribe();
  }

}
