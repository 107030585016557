import { ChangeDetectionStrategy, Component, OnDestroy, ChangeDetectorRef, ApplicationRef, Renderer2, ElementRef } from '@angular/core';
import { SearchService } from '../../APIs/search.service';
import { SharedService } from '../../shared.service';
import { CardService } from '../../APIs/card.service';
import { Paginator } from '../../paginator';
import { RouteService } from 'src/app/services/route.service';
import { BookmarkService } from '../../APIs/bookmarked.service';
import { GA4Service } from 'src/app/tag-manager/push-items';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastService } from '../../../app/shared.service';
import { Subscription } from 'rxjs';
import { AmpliAnalyticsService } from 'src/app/tag-manager/amplitude';

interface ResourceForBookmarkIcon {
  resourceId: number;
  isFavorite: boolean;
}

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SearchPageComponent implements OnDestroy {

  searchCards: any[] = [];
  searchData: any;
  searchTerm: any = '';  
  activeResContentType: any = null;  
  
  allBlock: any[] = [];
  resContentTypes: any[] = []; 
  resContentTypesForHTML: any[] = [];
  totalItems: number = 0;
  take: number = 0;
  skip: number = 0;
  total: number = 0;
  pagesAmount: number = 0;
  pagesStep: number = 0;
  pagesList: any[] = [];
  activePage: number = 1;
  pageNum: number = 0;
  prevPage: number = 0;
  nextPage: number = 0;
  selected = "Most Relevant";
  selectedValue: any = "MostRelevant";
  apiStatus: string = 'pending';
  searchWordForPaginator: any = "";
  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;

  constructor(
    private appRef: ApplicationRef, private searchService: SearchService, 
    private dataService: SharedService, private cardService: CardService, private paginator: Paginator, 
    private routeService: RouteService, private BookmarkService: BookmarkService, private cdr: ChangeDetectorRef, 
    private tagService: GA4Service, private renderer: Renderer2, private el: ElementRef, private sanitizer: DomSanitizer, 
    private ampliAnalyticsService: AmpliAnalyticsService,
    private toastService: ToastService
    ) {}

   ngOnInit(): void {   
    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval); 
      }
      else if (showToastStatus === "Closed"){
        clearInterval(interval); 
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);
  
    this.showToastSubscription = this.toastService.showToast$.subscribe(showToast => {
      this.extraPaddingContent = showToast;
    });

    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.focus();
    }  
    this.searchTerm = sessionStorage.getItem("SearchWord");
    if(this.searchTerm == null){
      this.searchTerm = sessionStorage.setItem("SearchWord", "");
      this.searchTerm = sessionStorage.getItem("SearchWord");
      this.getResultsWithSortCall();
    } else{
      this.getResultsWithSortCall();
    }

    this.searchWordForPaginator = sessionStorage.getItem("SearchWord");    
    this.tagService.refreshPush();


    this.cardService.getResourceContentTypes().subscribe((data) => {
      const resData = data as { contentTypes: any[] };            
      this.resContentTypes = resData.contentTypes;
      this.resContentTypesForHTML =  this.resContentTypes.filter(x => x.languageCode == localStorage.getItem("siteLang"));
      this.apiStatus = 'success';
    });
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem("SearchWord");
    this.showToastSubscription?.unsubscribe();
  }

  getResultsWithSortCall(){
    // Perform search based on the received searchTerm
    this.searchService.getResultsWithSort(this.searchTerm, null, this.selectedValue).subscribe((data) => {
      const searchData = data as { total: number; items: any[] };
      this.searchCards = this.filterAndHighlightCards(searchData.items);
      this.totalItems = searchData.total;
      this.searchData = searchData;
      this.paginatorLoader(data);
      this.tagService.siteSearchResultPush(this.searchTerm, this.totalItems);
      this.ampliAnalyticsService.ampliSiteSearchResultPush(this.searchTerm, this.totalItems);
      this.apiStatus = 'success';
    });
  }


  filterAndHighlightCards(cards: any[]): any[] {
    const lowerCaseSearchTerm = this.searchTerm.toLowerCase();
    return cards.map((card) => {
      const cardCopy = { ...card };
      cardCopy.categoryTitle = this.highlightMatches(card.categoryTitle, lowerCaseSearchTerm);
      if (card.categoryDescription) {
        cardCopy.categoryDescription = this.highlightMatches(card.categoryDescription, lowerCaseSearchTerm);
      }
      return cardCopy;
    });
  }

  highlightMatches(text: string, searchTerm: string): any {
    if (!text || !searchTerm) {
      return text || '';
    }
  
    const regex = new RegExp(searchTerm, 'gi');
    const highlightedText = text.replace(regex, (match) => `<span class="highlighted">${match}</span>`);
  
    return this.sanitizer.bypassSecurityTrustHtml(highlightedText);
  }
  


  changeItem(event: any, resContentType: any){   
    const searchWord = sessionStorage.getItem('SearchWord');
  
    if (this.searchTerm.trim() !== '' && this.searchTerm.trim() === searchWord) {
      this.tagService.refinePush(event.srcElement.innerText);
      this.activeResContentType = resContentType;
      var activeEl;
      activeEl = document.getElementsByClassName("sg-selector active")[0];
      if (event.target.classList.contains("active")){
        return;
      } else {
        event.target.classList.add('active');
        activeEl.classList.remove('active');
      }    
      
      this.searchService.getResultsWithSort(this.searchTerm , resContentType, "").subscribe((data) => {
        const searchData = data as { total: number; items: any[] };   
        this.allBlock = searchData.items;
        this.totalItems = searchData.total;
        this.searchCards = searchData.items;      
        this.paginatorLoader(data);
        this.tagService.siteSearchResultPush(this.searchTerm, this.totalItems);
        this.ampliAnalyticsService.ampliSiteSearchResultPush(this.searchTerm, this.totalItems);
        this.apiStatus = 'success';
      });
    } else {
      const lastSearchWord = sessionStorage.getItem('SearchWord');
      this.searchTerm = lastSearchWord || '';

      this.tagService.refinePush(event.srcElement.innerText);
      this.activeResContentType = resContentType;
      var activeEl;
      activeEl = document.getElementsByClassName("sg-selector active")[0];
      if (event.target.classList.contains("active")){
        return;
      } else {
        event.target.classList.add('active');
        activeEl.classList.remove('active');
      }    
      
      this.searchService.getResultsWithSort(this.searchTerm , resContentType, "").subscribe((data) => {
        const searchData = data as { total: number; items: any[] };   
        this.allBlock = searchData.items;
        this.totalItems = searchData.total;
        this.searchCards = searchData.items;      
        this.paginatorLoader(data);
        this.tagService.siteSearchResultPush(this.searchTerm, this.totalItems);
        this.ampliAnalyticsService.ampliSiteSearchResultPush(this.searchTerm, this.totalItems);
        this.apiStatus = 'success';
      });
    }
  }

  searchItem(searchWord: string) { 
    sessionStorage.setItem("SearchWord", searchWord);
    this.searchWordForPaginator = searchWord;
    if (searchWord != ""){
      this.searchService.getSearchResults(this.searchTerm).subscribe((data) => {
        const searchData = data as {total: number; items: any[] };  
        this.searchCards = searchData.items;
        this.totalItems = searchData.total;
        this.searchData = searchData;
        this.paginatorLoader(data);
        this.tagService.siteSearchResultPush(this.searchTerm, this.totalItems);
        this.ampliAnalyticsService.ampliSiteSearchResultPush(this.searchTerm, this.totalItems);
        this.apiStatus = 'success';
      });
    }
  }

  changeSearchParameters(sort: any) {
    sessionStorage.setItem('SortingBy', sort);
  
    const searchWord = sessionStorage.getItem('SearchWord');
  
    if (this.searchTerm.trim() !== '' && this.searchTerm.trim() === searchWord) {
      this.searchService.getResultsWithSort(this.searchTerm, this.activeResContentType, sort).subscribe((data) => {
        const searchData = data as { items: any[] };
        this.searchCards = searchData.items;
        this.searchData = searchData;
        this.apiStatus = 'success';
      });
    } else {
      const lastSearchWord = sessionStorage.getItem('SearchWord');
      this.searchTerm = lastSearchWord || '';
      this.searchService.getResultsWithSort(this.searchTerm, this.activeResContentType, sort).subscribe((data) => {
        const searchData = data as { items: any[] };
        this.searchCards = searchData.items;
        this.searchData = searchData;
        this.apiStatus = 'success';
      });
    }
  }
  
  
  

  updatePagesList() {
    const maxPagesToShow = 5;
    const middlePage = Math.ceil(maxPagesToShow / 2);
  
    if (this.pagesAmount <= maxPagesToShow) {
      this.pagesList = Array.from({ length: this.pagesAmount }, (_, i) => i + 1);
    } else {
      if (this.activePage <= middlePage) {
        // Display the first maxPagesToShow pages and add the last page with ...
        this.pagesList = Array.from({ length: maxPagesToShow }, (_, i) => i + 1);
        this.pagesList.push('...');
        this.pagesList.push(this.pagesAmount);
      } else if (this.activePage > this.pagesAmount - middlePage) {
        // Display the last maxPagesToShow pages, including the first page with ...
        this.pagesList = [1, '...'];
        this.pagesList = this.pagesList.concat(
          Array.from({ length: maxPagesToShow - 2 }, (_, i) => this.pagesAmount - maxPagesToShow + i + 3)
        );
      } 
      else {
        // Display the current page and the pages around it with the first and last page with ...
        this.pagesList = [1, '...'];
        this.pagesList = this.pagesList.concat(
          Array.from({ length: maxPagesToShow - 2 }, (_, i) => this.activePage - middlePage + i + 2)
        );
        this.pagesList.push('...');
        this.pagesList.push(this.pagesAmount);
      }
    }
  }

  paginatorLoader(data: any){
    this.pagesList = [];
    this.pagesStep = 0;
    this.take = parseInt(data.take);
    this.skip = parseInt(data.skip);
    this.total = parseInt(data.total);
    this.activePage = 1;
    this.paginator.createPaginationParameters(this.take, this.skip, this.total).subscribe((parameters) => {
      this.pagesAmount = parameters[0];
      for(let i = 0; i < this.pagesAmount; i++){   
          this.pagesStep = this.pagesStep + 1;
          this.pagesList.push(this.pagesStep);              
        }  
        this.apiStatus = 'success';      
    });
    document.getElementById("arrowPrev")?.classList.add("hidden");
    setTimeout("document.getElementById('page1')?.classList.add('active')", 1000); 

    this.updatePagesList();  
  }

  changePage(pageNum: number, arrowButton: any){
    var activeNum = document.querySelectorAll(".custom-page.active");
    if (activeNum && activeNum.length > 0) {
      activeNum[0].classList.remove("active");
    }
        
    if (pageNum > 0){
      this.activePage = pageNum;      
    } else if (pageNum == 0 && arrowButton == true){
      this.activePage = this.activePage - 1;
    } else if (pageNum == 0 && arrowButton == false){
      this.activePage = this.activePage + 1;
    }
    document.getElementById("page" + this.activePage)?.classList.add("active");
    if (this.activePage == 1){
      document.getElementById("arrowPrev")?.classList.add("hidden");
      document.getElementById("arrowNext")?.classList.remove("hidden");
    } else if (this.activePage == this.pagesAmount){
      document.getElementById("arrowPrev")?.classList.remove("hidden");
      document.getElementById("arrowNext")?.classList.add("hidden");
    } else {
      document.getElementById("arrowPrev")?.classList.remove("hidden");
      document.getElementById("arrowNext")?.classList.remove("hidden");
    }
    
    this.skip = 0;
    // if arrowButton is true - we clicked Previous Page, is false - we clicked Next Page, if null we clicked on page number
    
    this.take = 10;
    this.skip = this.activePage - 1;    
    if (this.activeResContentType == undefined){
      this.activeResContentType = null;
    }

    var sorting = sessionStorage.getItem("SortingBy");
    if (!sorting?.length){
      sorting = null;
    }
    
    this.searchService.getResultsFullwithSorting(this.searchWordForPaginator, this.activeResContentType, null, this.take, this.skip, sorting).subscribe((data) => {
      const searchData = data as { items: any[] };   
      this.searchCards = searchData.items;
    });

    this.updatePagesList();
  }

  onBookmarkIconClick(resource: ResourceForBookmarkIcon, title: any) {
    const resourceId = resource.resourceId;
  
    this.BookmarkService.postBookmark(resourceId).subscribe(
      (response) => {
        if (!resource.isFavorite){
          this.tagService.resourceEngagement('bookmark', title);
        } else {
          this.tagService.resourceEngagement('remove bookmark', title);
        }
        // Update isFavorite based on the response from the API
        resource.isFavorite = !resource.isFavorite;
  
        // Modify the following part based on your component's structure
        const index = this.searchCards.findIndex((item) => item.resourceId === resourceId);
  
        if (index !== -1) {
          // Update isFavorite in the searchCards array
          this.searchCards[index].isFavorite = resource.isFavorite;
        } else {
          // Handle the case when the item is not found in the searchCards array
          // You may want to add it to the array if isFavorite is true
          if (resource.isFavorite) {
            this.searchCards.push(response); // Use the actual response from the API
          }
        }
        this.ampliAnalyticsService.ampliResourceEngagementBookmarkPush(title);
        // Trigger change detection to update the UI
        this.cdr.detectChanges();
      },
      (error) => {
        // Handle error if needed
      }
    );
  }

  resourceSearchGA4Push(category: any, title: any, contentType: any, selectionType: any){
    this.tagService.resourceSelectedSearchPush(category, title, contentType, selectionType, this.searchTerm, this.totalItems);
  }

  ampliResourceSearchPush(category: any, title: any, contentType: any){
    this.ampliAnalyticsService.ampliResourceSelectedSearchPush(category, title, contentType, this.searchTerm, this.totalItems);
  }

  showTooltip(id:any){
    document.getElementById(id.id)?.setAttribute("style", "display: block;");;
  }

  hideTooltip(id:any){
    document.getElementById(id.id)?.setAttribute("style", "display: none;");;
  }

}