import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  NgModule,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { MatMenuTrigger } from "@angular/material/menu";
import { CommonModule } from "@angular/common";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { SharedService } from "../../shared.service";
import { MatDrawer } from "@angular/material/sidenav";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { ProgressService } from "../../APIs/progress.service";
import AOS from "aos";
import { FooterService } from "../../shared.service";
import { GA4Service } from "src/app/tag-manager/push-items";
import { SharedModule } from "../../shared.module";
import { TranslateService } from "@ngx-translate/core";
import { ToastService } from "../../../app/shared.service";
import { ToastCommunicationService } from "../../../app/shared.service";
import { Subscription } from "rxjs";
import { HelperService } from "../../services/helper.service";
import { CMSService } from "src/app/APIs/cms_content/allSections.service";
import { CMSServiceGeneralInfo } from "src/app/APIs/cms_content/generalInfo.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { forkJoin } from "rxjs";
import { CMSServiceModalInDetailedSection } from "src/app/APIs/cms_content/modalInTheDetailedSections.service";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AmpliAnalyticsService } from "src/app/tag-manager/amplitude";

@Component({
  selector: "app-detailed-section",
  templateUrl: "./detailed-section.component.html",
  styleUrls: ["./detailed-section.component.scss"],
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    CommonModule,
    RouterModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatDialogModule,
    SharedModule,
    MatProgressSpinnerModule
  ],
})
export class DetailedSectionComponent implements OnInit, OnDestroy {
  @ViewChild("drawer", { static: false }) drawer!: MatDrawer;

  isApiLoading: boolean = true;
  sectionIdForModal: string | null = null;
  backButtonText: string = "";
  // currentPartIndex: number = 1; // Store the current part index
  apiData: any;

  sectionsFromApi: any[] = [];
  footerParts: any[] = [];
  sideNavParts: any[] = [];
  sideNavSections: any[] = [];
  dynamicCurrentSectionIndex: number | null = null;
  dynamicCurrentSectionIndexForSideNav: number[] = [];
  completedSectionsPerPart: boolean[][] = [];
  currentPartIndex: number = 0;
  dynamicSectionStatus: boolean[] = [];
  currentSectionName: string = "";

  partNumber: string = "";
  partName: string = "";

  sectionNumber: string | number = "Unknown";

  isActiveButton: boolean = false;
  private lastTimeout: any;

  private showToastSubscription: Subscription | undefined;
  extraPaddingContent: boolean = false;
  showToast: boolean = false;
  marketValue: any = this.helperService.getMarket()?.toUpperCase();
  showExtraPadding: boolean = false;
  isToastClosed: string = "";

  apiLoadStatus = {
    sectionsLoaded: false,
    generalInfoLoaded: false,
  };
  detailedSectionsMap: { [key: string]: string } = {};

  oldSectionsMap = new Map<number, any[]>();
  newDetailedSectionsMap = new Map<number, any[]>();

  private onAutoScroll = (): void => {
    if (this.lastTimeout) {
      clearTimeout(this.lastTimeout);
    }

    this.lastTimeout = setTimeout(() => {
      window.removeEventListener("scroll", this.onAutoScroll, true);
      window.scrollBy({
        top: -100,
        behavior: "smooth",
      });
    }, 100);
  };

  toggleMenu() {
    if (this.drawer) {
      this.drawer.toggle();
      this.isActiveButton = !this.isActiveButton;
    } else {
    }
  }

  getCompletedSectionsCount(partIndex: number): number {
    return (
      this.completedSectionsPerPart[partIndex]?.filter((status) => status)
        .length || 0
    );
  }

  getTotalSectionsCount(partIndex: number): number {
    return this.sideNavSections[partIndex]?.length || 0;
  }

  isSectionCompleted(sectionId: string): boolean {
    const completed = this.progressData.some(
      (progress) => progress.objectId === sectionId
    );
    return completed;
  }

  hideConfirmation() {
    this.showToast = false;
    this.showExtraPadding = false;
    this.toastService.setShowToast(false);
    this.isToastClosed = "Closed";
    sessionStorage.setItem("showToastClosed", this.isToastClosed);
  }

  closeToastOnClick() {
    this.toastCommunicationService.closeToast();
  }

  sectionStatus: boolean[] = new Array(11).fill(false);
  secondSectionStatus: boolean[] = new Array(6).fill(false);

  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  sectionId: string = "";
  applyClass: boolean = false;
  id: string = "";
  firstSection: boolean = true;
  secondSection: boolean = true;
  sectionTitle: string = "";

  currentSection: any = {};
  modalsData: any[] = [];
  iconModalMap: { [iconId: string]: string } = {};

  constructor(
    private route: ActivatedRoute,
    private sharedService: SharedService,
    public dialog: MatDialog,
    private progressService: ProgressService,
    private footerService: FooterService,
    private routeService: RouteService,
    private tagService: GA4Service,
    private translateService: TranslateService,
    private toastService: ToastService,
    private toastCommunicationService: ToastCommunicationService,
    private helperService: HelperService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private cmsService: CMSService,
    private cmsServiceGeneralInfo: CMSServiceGeneralInfo,
    private sanitizer: DomSanitizer,
    private cmsServiceModal: CMSServiceModalInDetailedSection,
    private ampliAnalyticsService: AmpliAnalyticsService
  ) {}

  features: any[] = [];
  progressData: any[] = [];

  hideFooter() {
    this.footerService.toggleFooterVisibility(false);
  }
  showFooter() {
    this.footerService.toggleFooterVisibility(true);
  }

  navigateBack(): void {
    // Construct the route with the shared section ID as a fragment
    const partIndex = this.currentPartIndex + 1; // Adjust for 1-based index
    this.router.navigate([`/onboarding/part`, partIndex], {
      fragment: this.sectionId, // Use the shared sectionId directly as the fragment
    });
  }

  ngOnInit(): void {
    this.isApiLoading = true;

    this.route.params.subscribe(async (params) => {
      this.sectionId = params["sectionId"];
      this.currentPartIndex = parseInt(params["partIndex"], 10) - 1 || 0;
      try {
        // Step 1: Load CMS data
        await this.loadCmsData();

        // Step 2: Calculate sectionNumber after CMS data is loaded
        this.sectionNumber = this.calculateCurrentSectionNumber();

        // Step 3: Fetch modals after sectionNumber is determined
        await this.fetchModalsForCurrentSection();
        this.assignModalsToIcons();
        this.filterIconsForDisplay();

      } catch (error) {
      }
    });

    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        this.scrollToSectionById(fragment); // Scroll to the correct section
      }
    });

    this.toastService.showToast$.subscribe((showToast) => {
      this.showToast = showToast;
    });

    const showToastStatus = sessionStorage.getItem("showToastClosed");
    const interval = setInterval(() => {
      if (showToastStatus === "Open") {
        this.extraPaddingContent = true;
        clearInterval(interval);
      } else if (showToastStatus === "Closed") {
        clearInterval(interval);
      }
      setTimeout(() => {
        clearInterval(interval);
      }, 10000);
    }, 100);

    this.showToastSubscription = this.toastService.showToast$.subscribe(
      (showToast) => {
        this.extraPaddingContent = showToast;
      }
    );

    const mainLogo = document.getElementById("mainLogo");
    if (mainLogo) {
      mainLogo.focus();
    }
    this.hideFooter();
    this.tagService.refreshPush();
    AOS.init();
  }

  setSectionCompleted(sectionId: string): void {
    if (!sectionId) {
      return;
    }

    const payload = { feature: "OB", objectId: sectionId };

    this.progressService.postProgress(payload).subscribe(
      () => {
        const currentIndex = this.sectionsFromApi.findIndex(
          (section) => section.uniqueID === sectionId
        );

        if (currentIndex !== -1) {
          this.completedSectionsPerPart[this.currentPartIndex][currentIndex] =
            true; // Mark as complete locally
        }

        this.navigateToNextSection(); // Navigate to the next section
      },
      (error) => {
      }
    );

    this.ampliAnalyticsService.ampliCompleteSectionPush(this.currentSection.sectionName);
  }

  async fetchModalsForCurrentSection(): Promise<void> {
    const modalPartNumber = (this.currentPartIndex + 1).toString();
    const modalSectionNumber = this.sectionNumber.toString();

    return new Promise((resolve, reject) => {
      this.cmsServiceModal
        .fetchModals(modalSectionNumber, modalPartNumber)
        .subscribe({
          next: (modals) => {
            if (modals.items && Array.isArray(modals.items)) {
              this.modalsData = modals.items.map((modal: any) => ({
                id: modal.id,
                header: modal.properties.header || "",
                mainContent: modal.properties.mainContent || "",
                footer: modal.properties.footer || "",
                modalSectionNumber:
                  modal.properties.modalSectionNumber || "Unknown",
              }));
            }
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  async loadCmsData(): Promise<void> {
    return new Promise((resolve, reject) => {
      forkJoin({
        allSections: this.cmsService.fetchSections(),
        generalInfo: this.cmsServiceGeneralInfo.fetchSections(),
        progressData: this.progressService.getProgress("OB"),
      }).subscribe({
        next: ({ allSections, generalInfo, progressData }) => {
          // Step 1: Process "allSections"
          if (allSections.items && Array.isArray(allSections.items)) {
            const sectionMap: { [key: number]: any[] } = {};
            this.oldSectionsMap = new Map();
            this.newDetailedSectionsMap = new Map();

            // Group sections by part
            allSections.items.forEach((section: any) => {
              const part = section.properties.partAssociated || 1;
              if (!this.oldSectionsMap.has(part)) {
                this.oldSectionsMap.set(part, []);
              }
              this.oldSectionsMap.get(part)?.push(section);
            });

            // Populate sections for the current part
            this.oldSectionsMap.forEach((sections, part) => {
              sectionMap[part] = sections.map((item: any) => ({
                id: item.id,
                partAssociated: item.properties.partAssociated || 1,
                sectionName: item.properties.sectionName || "Unnamed Section",
                mappedDetailedId: this.detailedSectionsMap[item.id] || null,
              }));
            });

            const partIndex = parseInt(
              this.route.snapshot.paramMap.get("partIndex") || "1",
              10
            );
            this.sectionsFromApi = sectionMap[partIndex] || [];

            this.sideNavSections = Object.values(sectionMap);

            this.dynamicSectionStatus = this.sectionsFromApi.map(() => false);
          }

          // Step 2: Transform "allSections" into a detailed view
          if (allSections.items && Array.isArray(allSections.items)) {
            this.sectionsFromApi = allSections.items.map((item: any) =>
              this.transformApiResponse(item)
            );
          }

          // Step 3: Process "generalInfo" for side navigation
          if (generalInfo.items && Array.isArray(generalInfo.items)) {
            this.sideNavParts = generalInfo.items.map((item: any) => ({
              partNumber: item.properties.partAssociatedGeneralInfo || "1",
              partName: item.properties.partName || "Unnamed Part",
              id: item.id,
            }));
          }

          // Step 4: Find and set the current section from "allSections"
          if (allSections.items && Array.isArray(allSections.items)) {
            const sections = allSections.items;
            this.currentSection = sections.find(
              (section: any) => section.id === this.sectionId
            );

            if (this.currentSection) {
              this.currentSection = this.transformApiResponse(
                this.currentSection
              );
              this.sectionsFromApi = [this.currentSection];
            }
          }

          // Step 5: Process progress data
        this.progressData = progressData.progresses || [];
        this.processProgressDataForAllParts(this.progressData);

          // Step 6: Assign modals to icons dynamically
          this.assignModalsToIcons();

          // Step 7: Update sectionNumber based on modals if available
          const modalWithSectionNumber = this.modalsData.find(
            (modal) => modal.modalSectionNumber
          );
          if (modalWithSectionNumber) {
            this.sectionNumber = modalWithSectionNumber.modalSectionNumber;
          } else {
            this.sectionNumber = "Unknown";
          }

          // Finalize by calculating sectionNumber if not already set
          this.sectionNumber = this.calculateCurrentSectionNumber().toString();
          resolve();

          this.isApiLoading = false; // Set to false once data is fully loaded
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  assignModalsToIcons(): void {
    if (!this.modalsData.length || !this.sectionsFromApi.length) {
      return;
    }

    this.sectionsFromApi.forEach((section) => {
      if (section.iconsOnThePage && section.iconsOnThePage.length) {
        section.iconsOnThePage.forEach((icon: any, index: number) => {
          const modal = this.modalsData[index];
          if (modal) {
            icon.modalId = modal.id; // Assign modal ID to icon
          }
        });
      }
    });
  }

  openModal(modalId: string): void {
    const modalData = this.modalsData.find((modal) => modal.id === modalId);
    if (modalData) {
      this.dialog.open(ModalDialogContent, {
        data: {
          modalContent: {
            header: modalData.header || "Default Header",
            mainContent: modalData.mainContent || "Default Content",
            footer: modalData.footer || "",
          },
        },
      });
    }
  }

  private transformApiResponse(response: any): any {
    return {
      uniqueID: response.id,
      sectionTitle: response.properties.sectionTitle || "",
      sectionNumber: response.properties.sectionNumber || "",
      sectionName: response.properties.sectionName || "",
      headingParagraph: response.properties.detailedHeadingParagraph || "",
      descriptionUnderHeadingParagraph:
        response.properties.detailedDescriptionUnderHeadingParagraph || "",
      instructionText: response.properties.detailedInstructionText || "",
      buttonText: response.properties.buttonText || "Complete Section",
      completionInstructionText:
        response.properties.detailedCompletionInstructionText || "",
      imagesOnThePage: this.extractImages(
        response.properties.detailedImagesOnTheDetailedPage || ""
      ),
      iconsOnThePage: this.extractIcons(
        response.properties.detailedModalSvg || ""
      ),
      footerText: response.properties.detailedSectionFooterContent || "",
    };
  }

  private extractImages(imagesHtml: string): any[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(imagesHtml, "text/html");
    const imgElements = doc.querySelectorAll("img");

    return Array.from(imgElements).map((img) => ({
      source: img.getAttribute("src"),
      alt: img.getAttribute("alt"),
    }));
  }

  getCurrentPartIndex(): string {
    return (this.currentPartIndex + 1).toString();
  }

  calculateCurrentSectionNumber(): number | string {
    const currentPartSections =
      this.sideNavSections[this.currentPartIndex] || [];
    const currentIndex = currentPartSections.findIndex(
      (section: any) => section.id === this.sectionId
    );

    if (currentIndex === -1) {
      const modalWithSectionNumber = this.modalsData.find(
        (modal) => modal.modalSectionNumber
      );
      if (modalWithSectionNumber) {
        return modalWithSectionNumber.modalSectionNumber || 1;
      }
      return 1;
    }

    return currentIndex + 1;
  }

  private extractIcons(iconsHtml: string): any[] {
    const parser = new DOMParser();
    const doc = parser.parseFromString(iconsHtml, "text/html");
    const figures = doc.querySelectorAll("figure");

    return Array.from(figures).map((figure) => {
      const img = figure.querySelector("img");
      const caption = figure.querySelector("figcaption")?.textContent || "";
      const isProIcon = caption.startsWith("pro_icon_"); // Check if it's a pro icon
      return {
        id: img?.getAttribute("alt") || "", // Use `alt` as the ID
        src: img?.getAttribute("src") || "", // Icon image source
        classNameForSVG: caption, // Use `figcaption` as className
        isProIcon, // Flag to differentiate icon type
      };
    });
  }

  // SIDE NAV

  loadAllSectionsAndProgress(): void {
    forkJoin({
      allSections: this.cmsService.fetchSections(),
      progressData: this.progressService.getProgress("OB"),
    }).subscribe(
      ({ allSections, progressData }) => {
        // Step 1: Process sections
        if (allSections.items && Array.isArray(allSections.items)) {
          const sectionsByPart = new Map<number, any[]>();
          allSections.items.forEach((section: any) => {
            const partNumber = parseInt(
              section.properties.partAssociated || "1",
              10
            );
            if (!sectionsByPart.has(partNumber)) {
              sectionsByPart.set(partNumber, []);
            }
            sectionsByPart.get(partNumber)?.push({
              id: section.id,
              partAssociated: section.properties.partAssociated,
              sectionName: section.properties.sectionName,
              ...section.properties,
            });
          });

          this.sideNavSections = Array.from(sectionsByPart.values());
        }

        // Step 2: Process progress
        this.progressData = progressData.progresses || [];

        // Step 3: Process completion data
        this.processProgressDataForAllParts(this.progressData);
      },
    );
  }

  processProgressDataForAllParts(progressData: any[]): void {
    progressData.forEach((entry) => {
      this.sideNavSections.forEach((sections, partIndex) => {
        if (!this.completedSectionsPerPart[partIndex]) {
          this.completedSectionsPerPart[partIndex] = [];
        }
        const sectionIndex = sections.findIndex(
          (s: { id: any }) => String(s.id) === String(entry.objectId)
        );
        if (sectionIndex !== -1) {
          this.completedSectionsPerPart[partIndex][sectionIndex] = true;
        }
      });
    });
  }
  

  loadSectionsAndProgress(): void {
    this.progressService.getProgress("OB").subscribe((data: any) => {
      this.progressData = data.progresses;
      this.processSectionsWithProgress();
    });
  }

  processSectionsWithProgress(): void {
    // Iterate through each part (sideNavSections) and check the progress for each section
    this.sideNavSections.forEach((sections, partIndex) => {
      sections.forEach((section: any, index: any) => {
        // Check if section ID is found in progressData
        const isCompleted = this.progressData.some(
          (progress) => progress.objectId === section.uniqueID
        );

        // Mark the section as completed or active based on progress
        if (isCompleted) {
          this.dynamicSectionStatus[index] = true; // Mark as completed
        } else {
          this.dynamicSectionStatus[index] = false; // Mark as not completed
        }

        // Now update the same status in the footer
        if (this.footerParts[partIndex]) {
          const footerSection = this.footerParts[partIndex].find(
            (footerSection: any) => footerSection.uniqueID === section.uniqueID
          );
          if (footerSection) {
            footerSection.isCompleted = isCompleted; // Update footer status
          }
        }
      });
    });
  }

  scrollToSectionById(fragment: string): void {
    setTimeout(() => {
      const sectionElement = document.getElementById(fragment);
      if (sectionElement) {
        const offset = -100; // Adjust for underscrolling by 100px
        const elementPosition =
          sectionElement.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({
          top: elementPosition,
          behavior: "smooth",
        });
      }
    }, 100); // Slight delay to ensure DOM is rendered
  }

  navigateToSection(partIndex: number, sectionId: string): void {
    this.router.navigate(["/onboarding/part", partIndex + 1], {
      fragment: sectionId,
    });
    this.closeSideMenu(); // Close the drawer after navigation
  }
  // END OF SIDE NAV

  // DYNAMIC: Fetch progress data for dynamic sections
  loadDynamicProgressData(): void {
    // this.progressService.getProgress("OB").subscribe((data: any) => {
    //   this.processDynamicProgressData(data.progresses); // Process dynamic sections progress
    // });
  }

  processDynamicProgressData(progressData: any[]): void {
    progressData.forEach((entry) => {
      this.sideNavSections.forEach((sections, partIndex) => {
        const sectionIndex = sections.findIndex(
          (s: { uniqueID: any }) =>
            String(s.uniqueID) === String(entry.objectId)
        );
        if (sectionIndex !== -1) {
          this.completedSectionsPerPart[partIndex][sectionIndex] = true;
        }
      });
    });
  }

  setDynamicSectionCompleted(sectionId: string, sectionName: string): void {
    // Logic for marking section as completed
    const currentIndex = this.sectionsFromApi.findIndex(
      (section) => section.uniqueID === sectionId
    );

    if (currentIndex === -1) {
      return;
    }

    const payload = { feature: "OB", objectId: sectionId };

    // Update progress in API
    this.progressService.postProgress(payload).subscribe(
      () => {
        // this.loadSectionsAndProgress(); // Refresh sections and progress data
      },
      (error) => {
      }
    );
  }

  stopDynamicAnimation(event: Event, iconId: string, section: any): void {
    const svgElement = event.currentTarget as SVGElement;
    const gElements = svgElement.querySelectorAll("g");
    gElements.forEach((gElement) => gElement.classList.toggle("paused"));

    const icon = section.iconsOnThePage.find((icon: any) => icon.id === iconId);
    if (icon) {
      if (icon.modalId) {
        this.openModal(icon.modalId); // Open the modal
      }
    }

    this.ampliAnalyticsService.ampliSectionModalOpenPush(section.sectionName);
  }

  openDynamicDialog(sectionId: string, iconId: number) {
    // Find the correct section first using the section ID
    const section = this.sectionsFromApi.find((s: any) => s.id === sectionId);

    if (section && section.modalContent) {
      // Now find the modal content within the correct section using the icon ID
      const modalContent = section.modalContent.find(
        (modal: any) => modal.id === iconId
      );

      if (modalContent) {
        this.dialog.open(ModalDialogContent, {
          data: { modalContent },
        });
      }
    }
  }

  navigateToNextSection(): void {
    // Get all sections in the current part
    const currentPartSections =
      this.sideNavSections[this.currentPartIndex] || [];

    // Find the index of the current section in the current part
    const currentIndex = currentPartSections.findIndex(
      (section: any) => section.id === this.sectionId
    );

    if (currentIndex === -1) {
      return;
    }

    // Check if there's a next section within the current part
    const nextSection = currentPartSections[currentIndex + 1];
    if (nextSection) {
      this.router.navigate(["/onboarding/part", this.currentPartIndex + 1], {
        fragment: nextSection.id,
      });
      return;
    }

    // If no next section, check for the next part
    const nextPartIndex = this.currentPartIndex + 1; // Move to the next part (0-based index)
    const nextPartSections = this.sideNavSections[nextPartIndex]; // Get sections for the next part

    if (nextPartSections && nextPartSections.length > 0) {
      // Navigate to the first section of the next part
      this.router.navigate(["/onboarding/part", nextPartIndex + 1], {
        fragment: nextPartSections[0].id,
      });
    } else {
      this.router.navigate(["/"]);
    }
  }

  closeMenu() {
    this.menuTrigger.closeMenu();
  }

  closeSideMenu(sectionId?: string): void {
    this.drawer.close();
    if (sectionId) {
      this.scrollToSectionById(sectionId); // Scroll to the specified section
    }
    this.loadCmsData();
  }

  ngOnDestroy(sectionId?: string): void {
    this.showFooter();
    this.showToastSubscription?.unsubscribe();

    if (sectionId) {
      this.scrollToSectionById(sectionId); // Scroll to the specified section
    }
  }

  downloadGA4Push() {
    this.tagService.downloadPush();
  }

  filterIconsForDisplay(): void {
    if (this.currentSection && this.currentSection.iconsOnThePage) {
      const proIcons = this.currentSection.iconsOnThePage.filter((icon: any) => icon.isProIcon);
      const regularIcons = this.currentSection.iconsOnThePage.filter((icon: any) => !icon.isProIcon);
  
      // Keep only the last ProTip
      const lastProIcon = proIcons.slice(-1);
  
      // Combine regular icons and the last ProTip
      this.currentSection.iconsOnThePage = [...regularIcons, ...lastProIcon];
    }
  }
}

import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Inject } from "@angular/core";
import { RouteService } from "src/app/services/route.service";

@Component({
  selector: "modal-dialog",
  templateUrl: "modal-dialog.html",
})
export class ModalDialogContent {
  modalContent: {
    header: string;
    mainContent: string;
    hasFooter?: boolean;
    footer?: string;
    closeButtonText?: string;
  } = {
    header: "",
    mainContent: "",
    hasFooter: false,
    footer: "",
    closeButtonText: "Got it",
  };

  pages: string[] = [];
  activeSlide: number = 1;
  isTextImageLayout: boolean = false;
  isImageLayout: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { modalContent: any }) {
    if (data && data.modalContent) {
      this.modalContent = data.modalContent;
      this.initializePages();
      this.checkLayoutType();
    }
  }

  checkLayoutType() {
    if (!this.modalContent.mainContent) return;

    const parser = new DOMParser();
    const contentDoc = parser.parseFromString(
      this.modalContent.mainContent,
      "text/html"
    );

    const fullText = contentDoc.body.innerHTML;

    // Check for "Text on the left - Image on the right"
    this.isTextImageLayout = fullText.includes(
      "(Text on the left - image on the right)"
    );

    // Check for "Images no text" layout
    this.isImageLayout = fullText.includes("(Images no text)");
  }

  // Extract image for rendering in "Just Image Layout"
  getImageFromPage(content: string): { src: string; alt: string } | null {
    const parser = new DOMParser();
    const contentDoc = parser.parseFromString(content, "text/html");

    const img = contentDoc.querySelector("img");
    if (img) {
      return {
        src: img.getAttribute("src") || "",
        alt: img.getAttribute("alt") || "Image",
      };
    }
    return null;
  }

  sanitizeAndHideImages(content: string): string {
    if (!this.isTextImageLayout) {
      return content; // No changes needed if not in image-text layout
    }

    const parser = new DOMParser();
    const doc = parser.parseFromString(content, "text/html");

    // Hide "(Text on the left - image on the right)"
    const paragraphs = doc.querySelectorAll("p");
    paragraphs.forEach((p) => {
      if (p.textContent?.includes("(Text on the left - image on the right)")) {
        p.innerHTML = `<span class="hideThisPageTitle">${p.innerHTML}</span>`;
      }
    });

    // Hide all <img> tags by adding the 'hideThisPageTitle' class
    const imgTags = doc.querySelectorAll("img");
    imgTags.forEach((img) => {
      img.classList.add("hideThisPageTitle");
    });

    return doc.body.innerHTML; // Return the modified content
  }

  // Method to initialize the pages
  initializePages() {
    if (!this.modalContent.mainContent) {
      this.pages = [];
      return;
    }

    const parser = new DOMParser();
    const contentDoc = parser.parseFromString(
      this.modalContent.mainContent,
      "text/html"
    );
    const fullText = contentDoc.body.innerHTML;

    const pageRegex = /Page\s*\d+:/g;
    const splitContent = fullText.split(pageRegex);
    const pageTitles = Array.from(fullText.matchAll(pageRegex)).map(
      (match) => match[0]
    );

    if (splitContent.length > 1) {
      this.pages = splitContent.slice(1).map((content, index) => {
        const pageTitle = pageTitles[index] || `Page ${index + 1}`;
        const hiddenTitle = `<span class="hideThisPageTitle">${pageTitle}</span>`;
        return `${hiddenTitle}${content.trim()}`;
      });
    } else {
      this.pages = [this.modalContent.mainContent];
    }
  }

  // Method to check if the "Previous" button should be displayed
  showPreviousButton(): boolean {
    return this.activeSlide > 1;
  }

  // Method to check if the "Next" button should be displayed
  showNextButton(): boolean {
    return this.activeSlide < this.pages.length;
  }

  // Method to go to the next slide
  goToNextSlide() {
    if (this.activeSlide < this.pages.length) {
      this.activeSlide++;
    }
  }

  // Method to go to the previous slide
  goToPreviousSlide() {
    if (this.activeSlide > 1) {
      this.activeSlide--;
    }
  }
}
