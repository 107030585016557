import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
} from "@angular/core";
import Rellax from "rellax";
import { SharedService } from "../../shared.service";
import { FooterService } from "../../shared.service";
import { ProgressService } from "../../APIs/progress.service";
import { MatDrawer } from "@angular/material/sidenav";
import AOS from "aos";
import { RouteService } from "src/app/services/route.service";
import { GA4Service } from "src/app/tag-manager/push-items";
import { TranslateService } from "@ngx-translate/core";
import {
  ToastService,
  ToastCommunicationService,
} from "../../shared.service";
import { Subscription } from "rxjs";
import { HelperService } from "../../services/helper.service";
// import { ApiService } from '../../APIs/interactiveMapSections';
import { ActivatedRoute, Router } from "@angular/router";
import { CMSService } from "src/app/APIs/cms_content/allSections.service";
import { CMSServiceGeneralInfo } from "src/app/APIs/cms_content/generalInfo.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { forkJoin } from "rxjs";
import { AmpliAnalyticsService } from "src/app/tag-manager/amplitude";

@Component({
  selector: "app-about-fge",
  templateUrl: "./about-fge.component.html",
  styleUrls: ["./about-fge.component.scss"],
})
export class AnimatedMapPart1Component
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("drawer", { static: false }) drawer!: MatDrawer;

  isApiLoading: boolean = true;
  contentHeight: number | undefined;
  isActiveButton: boolean = false;
  currentSectionIndex: number | null = null;
  marketValue: any = this.helperService.getMarket()?.toUpperCase();
  cmsData: any[] = [];

  private showToastSubscription: Subscription | undefined;
  private lastTimeout: any;
  private applyClassSubscription: Subscription = Subscription.EMPTY;

  extraPaddingContent: boolean = false;
  showToast: boolean = false;
  isToastClosed: string = "";
  sectionsFromApi: any[] = [];
  sideNavSections: any[] = [];
  sideNavParts: any[] = [];
  currentSectionName: string = "";
  dynamicSectionStatus: boolean[] = [];
  dynamicCurrentSectionIndex: number | null = null;
  dynamicCurrentSectionIndexForSideNav: number[] = [];
  partsFromApi: any[] = [];
  currentPartIndex: number = 0;
  completedSectionsPerPart: boolean[][] = [];
  applyClass: boolean = false;

  MAX_DELAY: number = 1500;
  MAX_DURATION: number = 1500;

  private subscriptions: Subscription = new Subscription();

  apiLoadStatus = {
    sectionsLoaded: false,
    generalInfoLoaded: false,
  };
  detailedSectionsMap: { [key: string]: string } = {};

  oldSectionsMap = new Map<number, any[]>();
  newDetailedSectionsMap = new Map<number, any[]>();

  constructor(
    private progressService: ProgressService,
    private footerService: FooterService,
    private routeService: RouteService,
    private tagService: GA4Service,
    private amplitudeService: AmpliAnalyticsService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private toastCommunicationService: ToastCommunicationService,
    private helperService: HelperService,
    // private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private cmsService: CMSService,
    private cmsServiceGeneralInfo: CMSServiceGeneralInfo,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.isApiLoading = true;

    this.loadCmsData();
    this.trackRouteChanges();

    this.initializeData(); // initialize data needed for the component
    this.initializeSubscriptions(); // setup subscriptions for observing events
    // this.initializeRellax(); // initialize Rellax animation
  }

  loadCmsData(): void {
    AOS.init(); // initialize AOS (Animate On Scroll)

    forkJoin({
      sections: this.cmsService.fetchSections(),
      generalInfo: this.cmsServiceGeneralInfo.fetchSections(),
    }).subscribe({
      next: ({ sections, generalInfo }) => {
        if (sections.items && Array.isArray(sections.items)) {
          this.processSections(sections.items); // Process sections into parts
        }
        if (generalInfo.items && Array.isArray(generalInfo.items)) {
          this.processGeneralInfo(generalInfo.items); // Process general info
        }
        this.cdr.detectChanges();
        this.loadProgressForAllParts(); // Load progress data
        this.isApiLoading = false; // Set to false once data is fully loaded
        setTimeout(() => this.initializeRellax(), 0); // Delay to ensure DOM is updated
      },
      error: (error) => {
      },
    });
  }

  processSections(sections: any[]): void {
    const sectionMap: { [key: number]: any[] } = {};

    // Group sections by part
    sections.forEach((section, index) => {
      const part = section.properties.partAssociated || 1;

      if (!sectionMap[part]) {
        sectionMap[part] = [];
      }

      const images = this.extractImages(section.properties.images_for_section);

      // Add processed section to the corresponding part
      sectionMap[part].push({
        id: section.id,
        sectionName: section.properties.sectionName || "Unnamed Section",
        sectionDescription: section.properties.sectionDescription || "",
        images: this.calculateImageAttributes(images),
        rellaxSpeed:
          (index % 2 === 0 ? 1 : -1) * (Math.floor(Math.random() * 5) + 1),
        delay: (index + 1) * 300, // Increment delay
        duration: 1500 - index * 100, // Decrease duration
      });
    });

    // Get the current part index from the route
    const partIndex = parseInt(
      this.route.snapshot.paramMap.get("partIndex") || "1",
      10
    );

    // Assign the sections of the current part to sectionsFromApi
    this.sectionsFromApi = sectionMap[partIndex] || [];
    this.sideNavSections = Object.values(sectionMap);

    // Initialize dynamicSectionStatus for the current part
    this.dynamicSectionStatus = this.sectionsFromApi.map(() => false);
  }

  processGeneralInfo(info: any[]): void {
    this.sideNavParts = info.map((item) => ({
      partNumber: item.properties.partAssociatedGeneralInfo || "1",
      partName: item.properties.partName || "Unnamed Part",
      breadCrumb: item.properties.breadCrumb || "",
      textAfterPartName: this.sanitizer.bypassSecurityTrustHtml(
        item.properties.textAfterPartName || ""
      ),
      id: item.id,
    }));
  }

  extractImages(imagesHtml: string): any[] {
    const parser = new DOMParser();
    const htmlContent = parser.parseFromString(imagesHtml, "text/html");
    const figures = htmlContent.querySelectorAll("figure");
    return Array.from(figures).map((figure) => {
      const imgElement = figure.querySelector("img");
      const figcaption = figure.querySelector("figcaption");
      return {
        src: imgElement?.getAttribute("src") || "",
        alt: imgElement?.getAttribute("alt") || "",
        animation: figcaption?.textContent?.trim() || "fade-in",
      };
    });
  }

  trackRouteChanges(): void {
    this.subscriptions.add(
      this.route.paramMap.subscribe((params) => {
        const partIndex = params.get("partIndex");
        if (partIndex) {
          this.currentPartIndex = parseInt(partIndex, 10) - 1;
        }
      })
    );
  }

  calculateSectionNumber(index: number): number {
    return index + 1; // Add 1 because indices are zero-based
  }

  navigateToSection(sectionId: string): void {
    this.router.navigate([], { fragment: sectionId });
  }

  ngAfterViewInit() {
    this.route.fragment.subscribe((fragment: string | null) => {
      if (fragment) {
        this.scrollToSectionById(fragment);
      }
    });

    if (!this.isApiLoading) {
      this.initializeRellax();
    }
  }

  scrollToSection(fragment: string): void {
    setTimeout(() => {
      const sectionElement = document.getElementById(fragment);
      if (sectionElement) {
        const offset = -100; // Adjust for underscrolling by 100px
        const elementPosition =
          sectionElement.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({
          top: elementPosition,
          behavior: "smooth",
        });
      }
    }, 100); // Slight delay to ensure DOM is rendered
  }

  scrollToSectionById(fragment: string): void {
    const scrollToElement = () => {
      const sectionElement = document.getElementById(fragment);
      if (sectionElement) {
        const offset = -100; // Adjust for underscrolling by 100px
        const elementPosition =
          sectionElement.getBoundingClientRect().top + window.scrollY + offset;
        window.scrollTo({
          top: elementPosition,
          behavior: "smooth",
        });
      } else {
        // Retry after a short delay
        setTimeout(scrollToElement, 100);
      }
    };

    scrollToElement(); // Start the recursive check
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    window.removeEventListener("scroll", this.onDynamicScroll, true);
    this.cleanUpSubscriptions(); // clean up all active subscriptions to avoid memory leaks
    this.showFooter(); // show footer when component is destroyed
  }

  // initialize data and load sections and progress
  initializeData(): void {
    this.route.paramMap.subscribe((params) => {
      const partIndex = params.get("partIndex"); // get part index from route parameters
      if (partIndex) {
        this.currentPartIndex = parseInt(partIndex, 10) - 1;
      }
      this.initializeCompletedSectionsForCurrentPart(); // initialize completed sections for the current part
    });
    this.trackDynamicScrolling(); // track scrolling to update section status
    this.trackDynamicScrollingForSideNav(); // track scrolling for side navigation
    this.hideFooter(); // hide footer while component is active
  }

  // setup subscriptions to handle toast visibility
  initializeSubscriptions(): void {
    this.toastService.showToast$.subscribe((showToast) => {
      this.showToast = showToast;
    });
    this.showToastSubscription = this.toastService.showToast$.subscribe(
      (showToast) => {
        this.extraPaddingContent = showToast;
      }
    );
  }

  // initialize Rellax animations for parallax effect
  initializeRellax(): void {
    if (this.isApiLoading) {
      // Delay initialization until API loading is complete
      return;
    }
  
    // Clear any existing Rellax instance
    const existingRellaxElements = document.querySelectorAll('.rellax');
    if (existingRellaxElements.length === 0) {
      return;
    }
  
    // Initialize Rellax
    new Rellax('.rellax', {
      center: true, // Adjust based on your design needs
    });
  
  }
  

  // clean up all active subscriptions and event listeners
  cleanUpSubscriptions(): void {
    window.removeEventListener("scroll", this.onDynamicScroll.bind(this), true);
    window.removeEventListener(
      "scroll",
      this.onDynamicScrollForSideNav.bind(this),
      true
    );
    this.showToastSubscription?.unsubscribe();
    this.applyClassSubscription.unsubscribe();
  }

  // toggle the visibility of the side navigation drawer
  toggleMenu(): void {
    this.drawer.toggle();
    this.isActiveButton = !this.isActiveButton;
  }

  // hide the footer of the page
  hideFooter(): void {
    this.footerService.toggleFooterVisibility(false);
  }

  // show the footer of the page
  showFooter(): void {
    this.footerService.toggleFooterVisibility(true);
  }

  // hide the confirmation toast
  hideConfirmation(): void {
    this.showToast = false;
    this.toastService.setShowToast(false);
    this.isToastClosed = "Closed";
    sessionStorage.setItem("showToastClosed", this.isToastClosed); // store toast state in session storage
  }

  // close the side navigation drawer
  closeSideMenu(sectionId?: string): void {
    this.drawer.close();
    if (sectionId) {
      this.scrollToSectionById(sectionId); // Scroll to the specified section
    }
    this.loadCmsData();
  }

  // load progress status for dynamic sections
  loadDynamicProgressStatus(): void {
    this.progressService.getProgress("OB").subscribe(
      (data: any) => {
        this.processDynamicProgressData(data.progresses); // process progress data
      },
      (error) => {
        this.routeService.serverError(error); // handle server error
      }
    );
  }

  loadDynamicProgressData(): void {
    this.progressService.getProgress("OB").subscribe((data: any) => {
      this.processDynamicProgressData(data.progresses); // Process dynamic sections progress
    });
  }

  // process progress data and update section completion status
  processDynamicProgressData(progressData: any[]): void {
    progressData.forEach((entry) => {
      this.sideNavSections.forEach((sections, partIndex) => {
        const sectionIndex = sections.findIndex(
          (s: { uniqueID: any }) =>
            String(s.uniqueID) === String(entry.objectId)
        );
        if (sectionIndex !== -1) {
          this.completedSectionsPerPart[partIndex][sectionIndex] = true;
        }
      });
    });
  }

  // track scrolling to update the current section in view
  trackDynamicScrolling(): void {
    window.addEventListener("scroll", this.onDynamicScroll.bind(this), true);
  }

  // update the current section based on the scroll position
  private onDynamicScroll = (): void => {
    const currentPosition = window.scrollY;
    const viewportHeight = window.innerHeight;
    const offset = viewportHeight * 0.1;

    for (let i = 0; i < this.sectionsFromApi.length; i++) {
      const sectionId = this.sectionsFromApi[i]?.id;
      const sectionElement = document.getElementById(sectionId);

      if (sectionElement) {
        const sectionTop = sectionElement.offsetTop;
        const sectionHeight = sectionElement.clientHeight;

        if (
          currentPosition >= sectionTop - offset &&
          currentPosition < sectionTop + sectionHeight
        ) {
          this.dynamicCurrentSectionIndex = i;
          this.currentSectionName = this.sectionsFromApi[i]?.sectionName; // Update current section name dynamically
          break;
        }
      }
    }

    this.cdr.detectChanges();
  };

  // track scrolling for side navigation updates
  trackDynamicScrollingForSideNav(): void {
    window.addEventListener(
      "scroll",
      this.onDynamicScrollForSideNav.bind(this),
      true
    );
  }

  // update the current section in side navigation based on scroll position
  private onDynamicScrollForSideNav = (): void => {
    const currentPosition = window.scrollY;
    const viewportHeight = window.innerHeight;
    const offset = (viewportHeight * 10) / 100;

    this.dynamicCurrentSectionIndexForSideNav = []; // Reset active indices

    // Guard against undefined
    const sections = this.sideNavSections[this.currentPartIndex];
    if (!sections || !Array.isArray(sections)) {
      return; // Exit the method if sections are undefined or not an array
    }

    for (let i = 0; i < sections.length; i++) {
      const sectionId = sections[i]?.id;
      const sectionElement = document.getElementById(sectionId);

      if (sectionElement) {
        const sectionTop = sectionElement.offsetTop;
        const sectionHeight = sectionElement.clientHeight;
        const sectionMiddle = sectionTop + sectionHeight / 4;

        if (
          currentPosition >= sectionMiddle - offset &&
          currentPosition < sectionTop + sectionHeight
        ) {
          this.dynamicCurrentSectionIndexForSideNav[this.currentPartIndex] = i; // Set active index only for current part
          break;
        }
      }
    }

    this.cdr.detectChanges();
  };

  // calculate image attributes like delay and duration for animations
  calculateImageAttributes(images: any[]): any[] {
    const numberOfImages = images.length;
    const step = this.MAX_DELAY / numberOfImages; // Calculate the delay step based on the number of images
    return images.map((image, index) => {
      return {
        ...image,
        delay: step * (index + 1), // Calculate delay for each image
        duration: this.MAX_DURATION - step * index, // Calculate duration for each image
        animation_placement: "top-bottom", // Set animation placement
      };
    });
  }

  // initialize completed sections array for the current part
  initializeCompletedSectionsForCurrentPart(): void {
    this.completedSectionsPerPart[this.currentPartIndex] =
      this.sectionsFromApi.map(() => false); // set all sections as incomplete initially
  }

  // load progress for all parts from API
  loadProgressForAllParts(): void {
    this.progressService.getProgress("OB").subscribe(
      (data: any) => {
        this.processProgressDataForAllParts(data.progresses); // Process progress data
        this.initializeDynamicSectionStatus(); // Ensure CMS-based sections have matching status
      },
      (error) => {
      }
    );
  }

  // New helper to initialize status for CMS-based sections
  initializeDynamicSectionStatus(): void {
    this.dynamicSectionStatus = this.sectionsFromApi.map(() => false); // Initialize all sections as incomplete
  }

  // process progress data for all parts and update completed status
  processProgressDataForAllParts(progressData: any[]): void {
    progressData.forEach((entry) => {
      this.sideNavSections.forEach((sections, partIndex) => {
        const sectionIndex = sections.findIndex(
          (s: { id: string }) => s.id === entry.objectId
        );
        if (sectionIndex !== -1) {
          if (!this.completedSectionsPerPart[partIndex]) {
            this.completedSectionsPerPart[partIndex] = [];
          }
          this.completedSectionsPerPart[partIndex][sectionIndex] = true;
        }
      });
    });
    this.cdr.detectChanges();
  }

  getCompletedSectionsCount(partIndex: number): number {
    return (
      this.completedSectionsPerPart[partIndex]?.filter((status) => status)
        .length || 0
    );
  }

  getTotalSectionsCount(partIndex: number): number {
    return this.sideNavSections[partIndex]?.length || 0;
  }

  // download push data for GA4 tracking
  downloadGA4Push(): void {
    this.tagService.downloadPush();
  }

  amplitudeViewSectionPush(sectionName: string): void {
    this.amplitudeService.ampliViewSectionPush(sectionName);
  }

  isContentShown: boolean = false;

  // toggle the visibility of content
  toggleContent(): void {
    this.isContentShown = !this.isContentShown;
  }

  // get the CSS class for a market
  getMarketClass(market: string | undefined): string {
    if (!market) return "";
    return `${market}-market`; // return market-specific class
  }

  // determine whether to show the button for the next part
  showNextPartButton(): boolean {
    return this.sideNavSections[this.currentPartIndex + 1]?.length > 0;
  }

  // navigate to the next part
  navigateToNextPart(): void {
    if (this.showNextPartButton()) {
      this.router
        .navigate([`/onboarding/part/${this.currentPartIndex + 2}`])
        .then(() => {
          this.loadCmsData(); // Reload data for the new part
          this.cdr.detectChanges();
        });
    }
  }

  // close the toast message on click
  closeToastOnClick(): void {
    this.toastCommunicationService.closeToast();
  }
}
